@use "utils/breakpoints";

// padding
.section-padding {
    padding: 120px 0;
}
.py-188 {
    padding: 188px 0;
}
.py-150 {
    padding: 150px 0;
}
.pt-150 {
    padding-top: 150px;
}
.pb-150 {
    padding-bottom: 150px;
}
.pb-120 {
    padding-bottom: 120px;
}
.pt-120 {
    padding-top: 120px;
}
.pb-110 {
    padding-bottom: 110px;
}
.pt-110 {
    padding-top: 110px;
}
.mb-110 {
    margin-bottom: 110px;
}
.mt-110 {
    margin-top: 110px;
}
.pb-250 {
    padding-bottom: 250px;
}
.pt-250 {
    padding-top: 250px;
}
.py-90 {
    padding: 90px 0;
}
.py-86 {
    padding: 86px 0;
}
.pb-200 {
    padding-bottom: 200px;
}
.w-80 {
    width: 80%;
}
.w-90 {
    width: 90%;
}
.w-fit-content {
    width: fit-content;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-10 {
    margin-bottom: 10px;
}
.pt-110 {
    padding-top: 110px;
}
.pb-110 {
    padding-bottom: 110px;
}
.pt-80 {
    padding-top: 80px;
}
.pb-80 {
    padding-bottom: 80px;
}
.mt-80 {
    margin-top: 80px;
}
.mb-80 {
    margin-bottom: 80px;
}
.top-100 {
    top: 100px;
}
.rounded-full {
    border-radius: 50%;
}
// variables end
.mask-image {
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
}
.mask-image-2 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}
.backdrop-filter {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    box-shadow:
        1px 2px 0px 0px rgba(255, 255, 255, 0.6) inset,
        -12px 36px 44px 0px rgba(127, 124, 168, 0.18);
}
.border-gradient {
    border-image-slice: 1;
    border-image-source: -webkit-linear-gradient(left, #6d4df2, #8c71ff) !important;
}
.text-gradient {
    background: -webkit-linear-gradient(left, #6d4df2, #8c71ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
// Swiper customize
.swiper-button-next,
.swiper-button-prev {
    --swiper-navigation-size: 24px;
    --swiper-navigation-top-offset: 50%;
    --swiper-navigation-sides-offset: 10px;
    --swiper-navigation-color: rgba(255, 255, 255, 0.2);
    display: flex;
    width: 56px;
    height: 56px;
    padding: 10px;
    font-size: 24px;
    --swiper-navigation-color: var(--tc-neutral-900);
    border-radius: 30px;
    border: none;
    &:hover,
    :active {
        border-color: var(--tc-theme-primary);
        background-color: var(--tc-theme-primary) !important;
        --swiper-navigation-color: var(--tc-system-white);
    }
}
.swiper-button-next::after,
.swiper-button-prev::after {
    content: '';
}
.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    text-align: center;
    line-height: 10px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 0;
    // top: var(--swiper-pagination-top,auto);
    left: 0;
    width: 100%;
}
.section-hero-4 {
    .swiper-pagination {
        text-align: start;
        padding-left: 85px;
    }
    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        text-align: center;
        line-height: 8px;
        font-size: 12px;
        color: #000;
        opacity: 1;
        background: var(--tc-neutral-200);
    }
    .swiper-pagination-bullet-active {
        color: #fff;
        background: var(--tc-theme-primary);
    }
}
.section-hero-5 {
    .swiper-pagination {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        text-align: center;
        line-height: 12px;
        font-size: 12px;
        color: #000;
        opacity: 1;
        background: var(--tc-system-white);
    }
    .swiper-pagination-bullet-active {
        width: 18px;
        height: 18px;
        text-align: center;
        line-height: 18px;
        font-size: 18px;
        color: #000;
        opacity: 1;
        color: #fff;
        background: var(--tc-theme-primary);
    }
    .img-pull {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}
.section-testimonial-3 {
    .swiper-pagination-bullet-active {
        width: 18px;
        height: 18px;
        text-align: center;
        line-height: 18px;
        font-size: 18px;
        color: #000;
        opacity: 1;
        color: #fff;
        background: var(--tc-theme-primary);
    }
    .swiper-pagination {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    text-align: center;
    line-height: 12px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: var(--tc-neutral-200);
}
.swiper-pagination-bullet-active {
    color: #fff;
    background: var(--tc-theme-primary);
}
.card-hover {
    transition: 0.3s ease-in-out;
    box-shadow: 0.2s ease-in-out;
    cursor: pointer;
    display: block;
    // & svg {
    //     fill: var(--tc-neutral-400);
    // }
    // .ic-arrow i {
    //     transition: all 0.2s;
    // }
    &:hover {
        transform: translateY(-0.25rem);
        box-shadow:
            0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
        // span {
        //     border-color: var(--tc-theme-primary) !important;
        // }
        // .card-text {
        //     color: var(--tc-theme-primary);
        // }
        // & svg {
        //     fill: var(--tc-theme-primary);
        // }
        // .ic-arrow i {
        //     transform: rotate(-45deg);
        //     transition: all 0.2s;
        // }
    }
}
.hover-up {
    transition: all 0.3s ease-in-out;
    &:hover {
        transform: translateY(-3px);
        transition-duration: 0.3s;
    }
}
.card-lift {
    transition:
        transform 0.2s ease-in-out,
        border-color 0.2s ease-in-out,
        background-color 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out;
    border-radius: 0.75rem;
    &:hover,
    &:focus,
    &.active {
        transform: translateY(-0.25rem);
        box-shadow:
            0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    }
}
.btn-gradient {
    transition: all 0.3s ease-in-out;
    &:hover {
        transform: translateY(-5px);
        transition: all 0.3s ease-in-out;
    }
}
// Hover effect start
.hover-effect {
    border: none;
    text-align: center;
    transition: all 0.5s;
    cursor: pointer;
}
.hover-effect {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}
.hover-effect:after {
    content: '🠚';
    color: var(--tc-theme-primary);
    position: absolute;
    opacity: 0;
    // top: 14px;
    left: -20px;
    transition: 0.5s;
}
.hover-effect:hover {
    color: #fff;
    padding-left: 30px;
    padding-right: 8px;
}
.hover-effect:hover:after {
    opacity: 1;
    left: 10px;
}
// #1
.hover-effect-1 {
    i {
        position: relative;
        transition: 0.5s;
    }
    &:hover i {
        padding-left: 10px;
    }
}
.tag-spacing {
    letter-spacing: 0.56px;
}
.form-control:focus {
    border-color: var(--tc-system-muted);
    box-shadow: none;
}
.z-4 {
    z-index: 4;
}
.z-5 {
    z-index: 5;
}
.text-highlight {
    position: relative;
    z-index: 1;
    span {
        display: inline-block;
        left: -3px;
        position: absolute;
        z-index: -1;
        width: calc(100% + 6px);
    }
}
// Borders
.border-top-dashed {
    border-top: dashed !important;
}
.border-bottom-dashed {
    border-bottom: dashed !important;
}
.border-end-dashed {
    border-right: dashed !important;
}
.border-start-dashed {
    border-left: dashed !important;
}
.link-hover-primary-light {
    &:hover {
        color: var(--tc-theme-primary-light) !important;
    }
}
.max-w-300px {
    max-width: 300px !important;
}
.section-cta-3 {
    background-image: url('..//imgs/cta-3/img-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
// style-css
.card-service-4 {
    .rectangle {
        transition: 0.3s ease-in-out;
        width: 146px;
        height: 7px;
        background: var(--tc-neutral-200);
    }
    &:hover {
        .rectangle {
            background: var(--tc-theme-primary-light);
            transition: 0.3s ease-in-out;
        }
        .icon-flip {
            .icon {
                transform: rotateY(180deg);
            }
        }
    }
}
.masonary-active {
    .project-item {
        margin: 15px 0;
    }
}
.grid-sizer,
.grid-item {
    width: 33.33%;
}
.section-case-studies {
    .grid-sizer,
    .grid-item {
        width: 50%;
    }
}
/* 2 columns */
.grid-item--width2 {
    width: 66.66%;
}
.rotate-90 {
    transform: rotate(-90deg);
}
.tag-home-5 {
    background: rgba(255, 255, 255, 0.5);
    box-shadow:
        1.313px 1.969px 0px 0px rgba(255, 255, 255, 0.6) inset,
        -12.177px 36.69px 44.901px 0px rgba(127, 124, 168, 0.18);
    backdrop-filter: blur(8.028975486755371px);
}
// nav-tabs
.nav-tabs {
    --bs-nav-tabs-border-width: var(--bs-border-width);
    --bs-nav-tabs-border-color: var(--bs-border-color);
    --bs-nav-tabs-border-radius: var(--bs-border-radius);
    --bs-nav-tabs-link-active-color: var(--tc-theme-primary);
    --bs-nav-tabs-link-hover-border-color: transparent;
    .underline {
        width: 37px;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        transition: 0.3s ease-in-out;
    }
}
.nav {
    --bs-nav-link-padding-x: 48px;
    --bs-nav-link-padding-y: 15px;
    --bs-nav-link-font-weight: var(--tc-fw-bold);
    --bs-nav-link-color: var(--tc-neutral-500);
    --bs-nav-link-hover-color: var(--tc-theme-primary);
}
.nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: 20px;
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: transparent;
    border-color: transparent;
    .underline {
        opacity: 1;
        transition: 0.3s ease-in-out;
    }
}
.section-features-9 {
    .bouncing-blobs-glass {
        background: rgba(238, 242, 255, 0.1);
        backdrop-filter: blur(100px);
        -webkit-backdrop-filter: blur(100px);
    }
}
.icon-height {
    max-height: 62px;
}
.section-cta-8 {
    .tag-year {
        left: -15%;
        bottom: -5%;
    }
    .tag-dots {
        top: -12%;
        right: -15%;
    }
}
.progress {
    background-color: var(--tc-system-white);
    overflow: unset;
    border-radius: 8px;
    .progress-bar {
        background-color: var(--tc-theme-primary);
        border-radius: 8px;
    }
    .vertical-line {
        height: 20px;
        width: 4px;
        background: var(--tc-theme-primary);
        display: block;
        position: absolute;
        bottom: 0;
    }
    .progress-number {
        position: absolute;
        top: -25px;
        margin-left: -18px;
        font-size: 1rem;
        color: var(--tc-neutral-900);
        font-weight: var(--tc-fw-medium);
    }
}
.section-cta-9 {
    .play-video {
        bottom: -20%;
        right: -20%;
        &:hover {
            .box-gradient {
                transform: rotate(-5deg);
                transition: 0.3s ease-in-out;
            }
        }
    }
    .box-gradient {
        width: 269px;
        height: 248px;
        transform: rotate(-2.821deg);
        border-radius: 16px;
        background: var(--tc-linear-1, linear-gradient(89deg, #d7cdff 0.92%, #f8e2ff 99.76%));
        right: -5%;
        top: -5%;
        transition: 0.3s ease-in-out;
    }
}
.section-contact-3 {
    .ellipse-rotate-success {
        width: 408.351px;
        height: 759.019px;
        transform: rotate(90.766deg);
        border-radius: 759.019px;
        opacity: 0.1;
        background: var(--tc-system-success);
        filter: blur(100px);
        top: -30%;
        left: 0%;
    }
    .ellipse-rotate-primary {
        width: 408.351px;
        height: 759.019px;
        transform: rotate(90.766deg);
        border-radius: 759.019px;
        opacity: 0.1;
        background: var(--tc-theme-primary);
        filter: blur(100px);
        top: 45%;
        left: -5%;
    }
    .ellipse-rotate-info {
        width: 592.451px;
        height: 1037.421px;
        transform: rotate(90.766deg);
        border-radius: 1037.421px;
        opacity: 0.15;
        background: #64ade1;
        filter: blur(100px);
        top: -60%;
        right: -5%;
    }
}
.ellipse-rotate-success {
    width: 419.259px;
    height: 842.878px;
    border-radius: 842.878px;
    opacity: 0.15;
    background: var(--tc-system-success);
    filter: blur(100px);
    top: -20%;
    left: 10%;
}
.ellipse-rotate-primary {
    width: 419.259px;
    height: 842.878px;
    border-radius: 842.878px;
    opacity: 0.15;
    background: var(--tc-theme-primary);
    filter: blur(100px);
    top: -15%;
    right: 10%;
}
.ellipse-rotate-success-sm {
    width: 362.825px;
    height: 381.241px;
    transform: rotate(94.838deg);
    border-radius: 381.241px;
    opacity: 0.1;
    background: #00ff1a;
    filter: blur(146.6436767578125px);
    top: -20%;
    left: 10%;
}
.ellipse-rotate-primary-sm {
    width: 286.712px;
    height: 576.406px;
    transform: rotate(90.766deg);
    border-radius: 576.406px;
    opacity: 0.15;
    background: var(--Theme-Primary, #6d4df2);
    filter: blur(68.38541412353516px);
    top: -15%;
    right: 10%;
}
.card-testimonial-3 {
    &:hover {
        svg {
            g {
                path {
                    fill: var(--tc-theme-primary);
                    transition: 0.2s ease-in-out;
                }
            }
        }
    }
    svg {
        g {
            path {
                transition: 0.2s ease-in-out;
            }
        }
    }
}
.bouncing-blob--infor-2 {
    background: #64ade1;
}
.rectangle-bg {
    bottom: -10px;
    right: -10px;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}
.bg-opacity-10 {
    background: rgba(255, 255, 255, 0.1);
}
.section-contact-1 {
    .input-group {
        .icon-input {
            background: rgba(255, 255, 255, 0.1) !important;
            min-height: 60px !important;
            display: flex;
            align-items: center;
        }
        .form-control {
            background: rgba(255, 255, 255, 0.1) !important;
            min-height: 60px !important;
            color: #fff !important;
            &::placeholder {
                color: #fff !important;
                opacity: 0.7 !important;
            }
        }
    }
}
.input-group {
    .icon-input {
        background: var(--tc-system-white) !important;
        min-height: 60px !important;
        display: flex;
        align-items: center;
    }
    .form-control {
        background: var(--tc-system-white);
        min-height: 60px !important;
        color: var(--tc-neutral-900) !important;
        transition: none !important;
        &::placeholder {
            color: var(--tc-neutral-400) !important;
        }
    }
    &.focus {
        .icon-input {
            border-color: var(--tc-theme-primary) !important;
        }
        .form-control {
            border-color: var(--tc-theme-primary) !important;
            transition: none !important;
        }
    }
}
.form-check-input:checked {
    background-color: var(--tc-theme-primary);
    border-color: transparent;
}
.form-check-input:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}
// Form Select
.form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    display: block;
    width: 100%;
    max-height: 60px;
    padding: 18px 19px 17px 17px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: var(--tc-neutral-900);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--tc-system-white);
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--tc-neutral-200);
    border-radius: 8px;
    transition: none;
    &:focus {
        border-color: var(--tc-neutral-900);
        outline: 0;
        box-shadow: none;
    }
}
// Range
input[type='range'] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 15px;
    /*  overflow: hidden;  remove this line*/
    /* New additions */
    height: 8px;
    background: var(--tc-neutral-200);
}
/* Thumb: webkit */
input[type='range']::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 15px;
    width: 15px;
    background-color: var(--tc-theme-primary);
    border-radius: 50%;
    border: 4px solid var(--tc-system-white);
    box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.1);
    transition: 0.2s ease-in-out;
}
/* Thumb: Firefox */
input[type='range']::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: var(--tc-theme-primary);
    border-radius: 50%;
    border: 4px solid var(--tc-system-white);
    box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.1);
    transition: 0.2s ease-in-out;
}
/* Hover, active & focus Thumb: Webkit */
input[type='range']::-webkit-slider-thumb:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}
input[type='range']:active::-webkit-slider-thumb {
    box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.1);
}
input[type='range']:focus::-webkit-slider-thumb {
    box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.1);
}
/* Hover, active & focus Thumb: Firfox */
input[type='range']::-moz-range-thumb:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}
input[type='range']:active::-moz-range-thumb {
    box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.1);
}
input[type='range']:focus::-moz-range-thumb {
    box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.1);
}
.section-contact-1 {
    background-image: url('../imgs/contact-1/img-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.section-page-header {
    .bouncing-blobs-glass {
        background-color: rgba(163, 140, 255, 0.1);
    }
}
.card-team {
    &:hover {
        .arrow-icon {
            transform: rotate(45deg);
            transition: 0.3s ease-in-out;
        }
    }
    .arrow-icon {
        transition: 0.3s ease-in-out;
    }
}
.section-testimonial-4 {
    .assessor {
        transition: 0.3s;
        &:hover {
            border-color: var(--tc-theme-primary-light) !important;
        }
    }
}
.section-services-details {
    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
        color: var(--tc-system-white);
        background-color: var(--tc-theme-primary);
    }
    .nav-link {
        display: block;
        padding: 19px 0px 19px 43px;
        font-size: 20px;
        background-color: white;
        margin-bottom: 8px;
        font-weight: var(--bs-nav-link-font-weight);
        color: var(--tc-neutral-900);
        background-color: var(--tc-neutral-100);
    }
}
.rectangle-banner3 {
    top: -20%;
    right: -5%;
    width: 95%;
    height: 100%;
    z-index: -1;
}
.head-1 {
    .avt-hero {
        margin-left: -5%;
    }
}
.contact-map {
    max-height: 650px;
    width: 100%;
    iframe {
        height: 650px;
        width: 100%;
    }
}
.pagination_item {
    &:hover {
        color: var(--tc-theme-primary) !important;
        transition: 0.3s ease-in-out;
        svg {
            path {
                stroke: var(--tc-theme-primary) !important;
                transition: 0.3s ease-in-out;
            }
        }
    }
    &:focus {
        box-shadow: none;
        background-color: var(--tc-theme-primary) !important;
        border-color: var(--tc-theme-primary);
        color: var(--tc-system-white) !important;
        transition: 0.3s ease-in-out;
        svg {
            path {
                stroke: var(--tc-system-white) !important;
                transition: 0.3s ease-in-out;
            }
        }
    }
}
.section-cta-14 {
    .vector-2 {
        right: -5.5%;
        top: 30%;
    }
}
.work-process-bg {
    padding-top: 450px;
    top: 80%;
}
.section-work-process {
    .square {
        top: -5%;
        right: -5%;
    }
    .square-2 {
        bottom: -5%;
        left: -5%;
    }
}
blockquote {
    color: #ffffff;
    text-align: center;
    font-size: 31px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 37.2px */
}
ul.timeline {
    list-style-type: none;
    position: relative;
    &::before {
        content: ' ';
        background: var(--tc-neutral-300);
        display: inline-block;
        position: absolute;
        left: 5px;
        width: 1px;
        height: 100%;
        z-index: 400;
    }
    .timeline-item {
        padding-left: 20px;
        &::before {
            content: ' ';
            background: var(--tc-system-white);
            display: inline-block;
            position: absolute;
            border-radius: 50%;
            border: 3px solid var(--tc-theme-primary);
            left: -5px;
            width: 20px;
            height: 20px;
            z-index: 400;
        }
        li {
            list-style-type: initial;
        }
    }
}
svg {
    .fill-dark {
        fill: var(--tc-system-dark);
    }
    .fill-white {
        fill: var(--tc-system-white);
    }
    .fill-primary {
        fill: var(--tc-theme-primary);
    }
    .fill-neutral-100 {
        fill: var(--tc-neutral-100);
    }
    .fill-primary-soft {
        fill: var(--tc-theme-primary-soft);
    }
    .stroke-dark {
        stroke: var(--tc-system-dark);
    }
    .stroke-white {
        stroke: var(--tc-system-white);
    }
}
.table-white {
    --bs-table-bg: var(--tc-system-white);
}
// media breakpoint
@media #{breakpoints.$lg} {
    .backdrop-filter-lg {
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(8.028975486755371px);
        box-shadow:
            1.313px 1.969px 0px 0px rgba(255, 255, 255, 0.6) inset,
            -12.177px 36.69px 44.901px 0px rgba(127, 124, 168, 0.18);
    }
    .backdrop-filter-md {
        background: none;
        backdrop-filter: unset;
        box-shadow: none;
    }
}
@media #{breakpoints.$md} {
    .backdrop-filter-md {
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(8.028975486755371px);
        box-shadow:
            1.313px 1.969px 0px 0px rgba(255, 255, 255, 0.6) inset,
            -12.177px 36.69px 44.901px 0px rgba(127, 124, 168, 0.18);
    }
    .backdrop-filter-lg {
        background: none;
        backdrop-filter: unset;
        box-shadow: none;
    }
    h1 {
        font-size: 60px !important;
    }
    h2 {
        font-size: 48px;
    }
    h3 {
        font-size: 38px;
    }
    h4 {
        font-size: 31px;
    }
    h5 {
        font-size: 25px;
    }
    h6 {
        font-size: 20px;
    }
    .compatible {
        img {
            width: 40px;
            height: 40px;
        }
    }
    .ds-3 {
        font-size: 56px !important;
    }
}
@media #{breakpoints.$xs} {
    h1 {
        font-size: 60px !important;
    }
    h2 {
        font-size: 48px;
    }
    h3 {
        font-size: 38px;
    }
    h4 {
        font-size: 28px;
    }
    h5 {
        font-size: 25px;
    }
    h6 {
        font-size: 20px;
    }
    .ds-1 {
        font-size: var(--tc-ds-3) !important;
    }
    .socials {
        transform: rotate(0deg) !important;
    }
    .ds-3 {
        font-size: 40px !important;
    }
    .nav {
        --bs-nav-link-padding-x: 20px;
        --bs-nav-link-padding-y: 15px;
    }
    .nav-link {
        font-size: 16px;
    }
    .tag-year {
        left: 0px !important;
        bottom: 0px !important;
    }
}
